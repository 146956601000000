<template>
  <div class="main">
    <b-tabs
      v-model="tabIndex"
      style="width: 100%"
    >
      <b-tab @click="callSellable">
        <template #title>
          <strong style="font-weight: 500; color: black">{{ $t("ProductsInStock") }}</strong>
        </template>
        <div style="display: flex;gap:10px;padding-top: 20px">
          <ProductsInStock @articleNumber="theArticleNumber" />
          <ProductsInStockLocations
            :article-name="articleName"
            :article-number="articleNumber"
          />
        </div>
      </b-tab>
      <b-tab @click="inStock">
        <template #title>
          <strong style="font-weight: 500; color: black">{{ $t("Recycle") }}</strong>
        </template>
        <div style="display: flex;gap:10px;padding-top: 10px">
          <ProductsInRecycle />
        </div>
      </b-tab>
      <b-tab @click="callSellable">
        <template #title>
          <strong style="font-weight: 500; color: black">{{ $t("InternalMoving") }}</strong>
        </template>
        <div style="display: flex;gap:10px;padding-top: 20px">
          <ProductsInStockInternal @articleNumber="theArticleNumber" />
          <ProductsInStockLocationsInternal
            :article-name="articleName"
            :article-number="articleNumber"
          />
        </div>
      </b-tab>
    </b-tabs>

  </div>
</template>

<script>
import ProductsInStock from '@/components/productsInStock/ProductsInStock.vue'
import ProductsInStockLocations from '@/components/productsInStock/ProductsInStockLocations.vue'
import ProductsInStockInternal from '@/components/productsInStock/ProductsInStockInternal.vue'
import ProductsInStockLocationsInternal from '@/components/productsInStock/ProductsInStockLocationsInternal.vue'
import ProductsInRecycle from '@/components/productsInStock/ProductsInRecycle.vue'
import { mapActions } from 'vuex'

export default {
  components: {
    ProductsInStock,
    ProductsInStockLocations,
    ProductsInRecycle,
    ProductsInStockInternal,
    ProductsInStockLocationsInternal,
  },
  data() {
    return {
      tabIndex: null,
      articleName: null,
    }
  },
  methods: {
    ...mapActions(['loadProductInStockList', 'resetTotalStockGroupedByLocationDedication', 'resetTotalStockGroupedByLocation', 'sellableProducts']),
    callSellable() {
      this.sellableProducts({
        pageNumber: 1,
        pageSize: 15,
      })
      this.resetTotalStockGroupedByLocation()
      this.resetTotalStockGroupedByLocationDedication()
    },
    theArticleNumber(value) {
      this.articleName = value
    },
    inStock() {
      this.loadProductInStockList({
        articleNumber: null,
        state: 'Recycle',
        pageNumber: 1,
        pageSize: 15,
      })
    },
  },
}

</script>

<style>
.main {
  display: flex;
  gap: 10px;
}
</style>
